html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :after, :before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  margin: 0;
  padding: 0;
}

.clear:before, .clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  zoom: 1;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
  display: block;
}

a:hover {
  color: none;
}

a:focus, a:hover, a:active {
  outline: 0;
}

input, textarea {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
  background-image: none;
  border: none;
}

input, textarea:focus {
  border: 0 solid #1d1d1b;
  outline: 0;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 1000px #fff !important;
}

html {
  color: #1d1d1b;
  background-color: #e2cca3;
  font-family: Raleway, sans-serif;
}

body {
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

body .thumbnail {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  position: absolute;
}

body .thumbnail .sombritude {
  width: 100%;
  height: 100%;
  opacity: .5;
  z-index: 0;
  background-color: #e2cca3;
  position: absolute;
  top: 0;
}

body .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  top: 0;
}

body nav.navigation {
  opacity: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  font-size: 20px;
  display: flex;
}

body nav.navigation p {
  margin-bottom: 30px;
}

body nav.navigation ul {
  display: flex;
}

body nav.navigation ul li {
  cursor: pointer;
  opacity: .5;
  font-weight: 500;
  transition: opacity .6s;
}

body nav.navigation ul li:nth-child(2) {
  margin: 0 50px;
}

body nav.navigation ul li img {
  display: none;
}

body nav.navigation ul li.active, body nav.navigation ul li:hover {
  opacity: 1;
}

body .middle {
  flex-direction: column;
  align-items: center;
  display: flex;
}

body .middle .logo {
  opacity: 0;
  margin-bottom: 50px;
  transform: rotate(0)scale(10);
}

body .middle .title {
  opacity: 0;
  width: 600px;
}

body .middle .title svg {
  width: 100%;
}

body .contact {
  opacity: 0;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  display: flex;
}

body .contact ul {
  margin-top: 30px;
  display: flex;
}

body .contact ul li {
  margin: 0 20px;
  font-weight: 500;
}

body .construct {
  opacity: 0;
  animation: 3s infinite fade;
}

body .construct p {
  text-transform: uppercase;
  margin-bottom: 100px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (width <= 768px) {
  body nav.navigation {
    margin-top: 50px;
  }

  body .title {
    width: 360px !important;
  }

  body .title svg {
    width: 100%;
  }

  body .logo {
    width: 250px;
    margin-bottom: 20px !important;
  }

  body .logo svg {
    width: 250px;
  }

  body .contact ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  body .contact ul li {
    margin: 5px 0;
  }

  body .construct p {
    margin-bottom: 50px;
  }
}

/*# sourceMappingURL=index.5cf0a245.css.map */
